import { createSlice } from "@reduxjs/toolkit";

import { changePassword } from "./changePasswordAction";

const initialState = {
  passwordData: {},
  message: null,
  success: null,
  loading: false,
};

export const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    setChangePasswordSuccessDrawer: (state, action) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(changePassword.fulfilled, (state, action) => {
        state.passwordData = action?.payload?.data?.detail;
        state.message = action?.payload?.message;
        state.success = true;
        state.loading = false;
      })
      .addCase(changePassword.pending, (state, action) => {
        state.message = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.detail?.message;
        state.loading = false;
        state.success = false;
      });
  },
});

export const { setChangePasswordSuccessDrawer } = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
