import { createSlice } from "@reduxjs/toolkit";

import {
  inviteUserStep1,
  inviteUserStep2,
  inviteUserStep3,
  invitedUserList,
  getJobRoles,
} from "./inviteAction";

const initialState = {
  job_roles: [],
  to_lock_form: true,
  invitedUsers: [],
  show_success_drawer: false,
  success: null,
  message: null,
  loading: false,
};

export const inviteSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {
    setInviteSuccessDrawer: (state, action) => {
      state.show_success_drawer = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(inviteUserStep1.fulfilled, (state, action) => {
        state.success = true;
        state.message = action?.payload?.data?.message;
        state.show_success_drawer = true;
        state.loading = false;
      })
      .addCase(inviteUserStep1.pending, (state, action) => {
        state.success = null;
        state.message = null;
        state.loading = true;
      })
      .addCase(inviteUserStep1.rejected, (state, action) => {
        state.success = false;
        state.message = action?.payload?.response?.data?.detail?.message;
        state.loading = false;
      })
      .addCase(inviteUserStep2.fulfilled, (state, action) => {
        if (action?.payload?.data?.message === "True") {
          state.to_lock_form = false;
        }
      })
      .addCase(inviteUserStep2.pending, (state) => {
        state.to_lock_form = true;
      })
      .addCase(inviteUserStep2.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.detail?.message;
        state.to_lock_form = true;
      })
      .addCase(inviteUserStep3.fulfilled, (state, action) => {
        state.message = action?.payload?.data?.message;
        state.success = true;
        state.loading = false;
      })
      .addCase(inviteUserStep3.pending, (state, action) => {
        state.message = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(inviteUserStep3.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.detail?.message;
        state.loading = false;
        state.success = false;
      })
      .addCase(invitedUserList.fulfilled, (state, action) => {
        state.success = true;
        state.invitedUsers = action?.payload?.data?.data;
        state.loading = false;
      })
      .addCase(invitedUserList.pending, (state, action) => {
        state.invitedUsers = [];
        state.success = null;
        state.message = null;
        state.loading = true;
      })
      .addCase(invitedUserList.rejected, (state, action) => {
        state.invitedUsers = [];
        state.success = false;
        state.message = null;
        state.loading = false;
      })
      .addCase(getJobRoles.fulfilled, (state, action) => {
        state.job_roles = action?.payload?.data?.data;
        state.success = true;
        state.loading = false;
      })
      .addCase(getJobRoles.pending, (state, action) => {
        state.job_roles = [];
        state.success = null;
        state.message = null;
        state.loading = true;
      })
      .addCase(getJobRoles.rejected, (state, action) => {
        state.job_roles = [];
        state.success = false;
        state.message = null;
        state.loading = false;
      });
  },
});

export const { setInviteSuccessDrawer } = inviteSlice.actions;

export default inviteSlice.reducer;
