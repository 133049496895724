// import {AUTH_TOKEN_NAME} from '../constants/defaultValues';

// import NotificationManager from '../components/common/react-notifications/NotificationManager';
import { LOGIN_URL } from "../config/const";
import Auth from "./auth.service";
import { history } from "./history";
import Storage from "./storage.service";

// request interceptor function
export const requestInterceptor = (request) => {
  if (Auth.token) {
    request.headers["Authorization"] = "Bearer " + Auth.token;
    // request.headers['Content-Type'] = 'multipart/form-data';
  }

  return request;
};

// response interceptor function for error
export const errorHandler = (error) => {
  if (
    error?.response?.status === 401 &&
    error?.response?.data?.detail?.message === "Could not validate credentials"
  ) {
    Storage.setFlash("error_message", "Session expired, please login again.");
    Auth.logout();
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({ ...error });
};

// response interceptor function for success
export const successHandler = (response) => {
  if (
    ["Token is Expired", "Could not validate credentials"].includes(
      response?.data?.message
    )
  ) {
    Storage.setFlash("error_message", "Session expired, please login again.");
    if (response?.data?.message === "Could not validate credentials") {
      Auth.logout();
    }
    return { data: { status: "error", data: [], Records: [] } };
  }

  return response;
};
