import { createAsyncThunk } from "@reduxjs/toolkit";

import Request from "../../helpers/request.service";

export const getUserTableDetails = createAsyncThunk(
  "userDetail/Table",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get(
        `/get-stf-data/${props?.id}`,
        props?.params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateComments = createAsyncThunk(
  "userDetail/updateComments",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.putJson(
        `/update-comment/${props?.slip_id}`,
        props?.data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGraphDataset = createAsyncThunk(
  "userDetail/graphData",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get(`/get-graph/${props?.user_id}`, {
        duration: props?.duration,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
