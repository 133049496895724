function FallbackUI() {
  return (
    <div>
      <section className="loading-wrapper">
        <span className="loader-3"> </span>
      </section>
    </div>
  );
}

export default FallbackUI;
