import { createAsyncThunk } from "@reduxjs/toolkit";

import Request from "../../helpers/request.service";

export const companyMasters = createAsyncThunk(
  "masters/Company",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get("/companies");
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const jobRolesMasters = createAsyncThunk(
  "masters/jobRoles",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get("/master/job-roles");

      return response?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
