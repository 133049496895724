import { createAsyncThunk } from "@reduxjs/toolkit";

import Request from "../../helpers/request.service";

export const associatesListing = createAsyncThunk(
  "associatesListing",
  async (props, { rejectWithValue }) => {
    try {
      let response;

      if (props?.company_id) {
        response = await Request.get(
          `/users/data?company_id=${props?.company_id}`
        );
      } else if (props?.name) {
        response = await Request.get(`/users/data?name=${props?.name}`);
      } else if (props?.date) {
        response = await Request.get(
          `/users/data?start_date=${props?.date[0]}&end_date=${props?.date[1]}`
        );
      } else {
        response = await Request.get("/users/data");
      }

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const associatesDelete = createAsyncThunk(
  "associatesDelete",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.delete(`/users/${props}`);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const riskStatusGraph = createAsyncThunk(
  "riskStatusGraph",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get(`/get-risk-status/${props.id}`, {
        duration: props.duration,
      });

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
