import { createAsyncThunk } from "@reduxjs/toolkit";

import Request from "../../helpers/request.service";

export const userProfileDetail = createAsyncThunk(
  "userProfileDetail",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get(`/users/${props}`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userProfileDetailUpdate = createAsyncThunk(
  "userProfileDetailUpdate",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.putJson(`/users/${props.id}`, props.data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
