import { createSlice } from "@reduxjs/toolkit";

import {
  forgotPasswordStep1,
  forgotPasswordStep2,
  forgotPasswordStep3,
} from "./forgotPasswordAction";

const initialState = {
  to_lock_form: true,
  data: [],
  message: null,
  success: null,
  loading: false,
};

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(forgotPasswordStep1.fulfilled, (state, action) => {
        state.message = action?.payload?.data?.message;
        state.success = true;
        state.loading = false;
      })
      .addCase(forgotPasswordStep1.pending, (state) => {
        state.message = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(forgotPasswordStep1.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.detail?.message;
        state.loading = false;
        state.success = false;
      })
      .addCase(forgotPasswordStep2.fulfilled, (state, action) => {
        if (action?.payload?.data?.message === "True") {
          state.to_lock_form = false;
        }
      })
      .addCase(forgotPasswordStep2.pending, (state) => {
        state.to_lock_form = true;
      })
      .addCase(forgotPasswordStep2.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.detail?.message;
        state.to_lock_form = true;
      })
      .addCase(forgotPasswordStep3.fulfilled, (state, action) => {
        state.message = action?.payload?.data?.message;
        state.success = true;
        state.loading = false;
      })
      .addCase(forgotPasswordStep3.pending, (state) => {
        state.message = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(forgotPasswordStep3.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.detail?.message;
        state.loading = false;
        state.success = false;
      });
  },
});

export default forgotPasswordSlice.reducer;
