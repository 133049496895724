import { createSlice } from "@reduxjs/toolkit";

import { companyMasters, jobRolesMasters } from "./masterAction";

const initialState = {
  loading: false,
  companyList: [],
  jobRolesList: [],
  success: null,
  message: null,
};

export const masterSlice = createSlice({
  name: "masterSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(companyMasters.fulfilled, (state, action) => {
        state.loading = false;
        state.companyList = action?.payload?.data?.data;
      })
      .addCase(companyMasters.pending, (state, action) => {
        state.loading = true;
        state.companyList = [];
      })
      .addCase(companyMasters.rejected, (state, action) => {
        state.loading = false;
        state.companyList = [];
      })

      .addCase(jobRolesMasters.fulfilled, (state, action) => {
        state.loading = false;
        state.jobRolesList = action?.payload?.data;
      })
      .addCase(jobRolesMasters.pending, (state, action) => {
        state.loading = true;
        state.jobRolesList = [];
      })
      .addCase(jobRolesMasters.rejected, (state, action) => {
        state.loading = false;
        state.jobRolesList = [];
      });
  },
});

export default masterSlice.reducer;
