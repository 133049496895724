import { createSlice } from "@reduxjs/toolkit";

import {
  getUserTableDetails,
  updateComments,
  getGraphDataset,
} from "./userDetailAction";

const initialState = {
  dataSet: [],
  tableData: [],
  message: null,
  success: null,
  loading: false,
  to_call: false,
};

export const userDetailSlice = createSlice({
  name: "userDeatil",
  initialState,
  reducers: {
    clearTableData: (state, action) => {
      state.tableData = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUserTableDetails.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data;
        state.success = true;
        state.loading = false;
      })
      .addCase(getUserTableDetails.pending, (state) => {
        state.tableData = [];
        state.message = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(getUserTableDetails.rejected, (state, action) => {
        state.tableData = [];
        state.message = action?.payload?.response?.data?.detail?.message;
        state.loading = false;
        state.success = false;
      })
      .addCase(getGraphDataset.fulfilled, (state, action) => {
        state.dataSet = action?.payload?.data?.data;
        state.success = true;
        state.loading = false;
      })
      .addCase(getGraphDataset.pending, (state) => {
        state.dataSet = [];
        state.message = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(getGraphDataset.rejected, (state, action) => {
        state.dataSet = [];
        state.message = action?.payload?.response?.data?.detail?.message;
        state.loading = false;
        state.success = false;
      })
      .addCase(updateComments.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.to_call = !state.to_call;
        }
      });
  },
});

export const { clearTableData } = userDetailSlice.actions;

export default userDetailSlice.reducer;
