import { createAsyncThunk } from "@reduxjs/toolkit";

import Request from "../../helpers/request.service";
import { history } from "../../helpers/history";

export const forgotPasswordStep1 = createAsyncThunk(
  "forgotPass/SendEmail",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.postJson("/forgot-password", props.data);
      if (response?.status === 200) {
        const state = { email: props.data.email };
        history.push("password-mailsent", state);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const forgotPasswordStep2 = createAsyncThunk(
  "forgotPass/Validate",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get("/validate-reset", "", {
        headers: {
          token: props?.token,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const forgotPasswordStep3 = createAsyncThunk(
  "forgotPass/ChangePassword",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.putJsonWithHeaders(
        "/reset-password",
        props?.data,
        {
          headers: {
            "intellisafe-token": props?.token,
          },
        }
      );
      if (response?.status === 200) {
        history.push("/user/password-updated");
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
