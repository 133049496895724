import { AUTH_TOKEN_NAME, LOGIN_URL, USER_OBJECT_NAME } from "../config/const";
import { history } from "./history";
import Storage from "./storage.service";

export default class Auth {
  static set token(token) {
    Storage.token = token;
  }

  static get token() {
    return Storage.token;
  }

  static logout() {
    Storage.remove(USER_OBJECT_NAME);
    Storage.remove(AUTH_TOKEN_NAME);
    history.push(LOGIN_URL);
  }

  static set user(user) {
    Storage.setObject(USER_OBJECT_NAME, user);
  }

  static get user() {
    return Storage.getObject(USER_OBJECT_NAME);
  }

  static get check() {
    const token = this.token;
    const user = this.user;
    if (token !== "undefined" && user !== "undefined") {
      return !(!token || !user);
    }
  }
}
