import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import "./index.css";
import { history } from "./helpers/history";
import HistoryRouter from "./helpers/HistoryRouter";
import { store } from "./store/storeConfig";

// custom packages added
import "bootstrap/dist/css/bootstrap.css";
import "./scss/style.scss";
import "./scss/responsive.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HistoryRouter history={history}>
      <Provider store={store}>
        <App />
      </Provider>
    </HistoryRouter>
  </React.StrictMode>
);
