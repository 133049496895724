import { createSlice } from "@reduxjs/toolkit";

import {
  associatesDelete,
  associatesListing,
  riskStatusGraph,
} from "./associatesListingAction";

const initialState = {
  associatesList: [],
  associateDelete: {},
  riskStatus: 0,
  risk: null,
  riskColor: null,
  re_call: false,
  message: null,
  success: null,
  loading: false,
};

export const associatesListingSlice = createSlice({
  name: "associatesListing",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(associatesListing.fulfilled, (state, action) => {
        state.associatesList = action?.payload?.data;
        state.message = action?.payload?.message;
        state.success = true;
        state.loading = false;
      })
      .addCase(associatesListing.pending, (state, action) => {
        state.message = null;
        state.token = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(associatesListing.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.message;
        state.loading = false;
        state.success = false;
      })

      .addCase(associatesDelete.fulfilled, (state, action) => {
        if (action?.payload?.status === 202) {
          state.re_call = !state.re_call;
        }
        state.associateDelete = action?.payload?.data?.data;
        state.message = action?.payload?.data?.message;
        state.success = true;
        state.loading = false;
      })
      .addCase(associatesDelete.pending, (state, action) => {
        state.message = null;
        state.token = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(associatesDelete.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.message;
        state.loading = false;
        state.success = false;
      })

      .addCase(riskStatusGraph.fulfilled, (state, action) => {
        state.riskStatus = action?.payload?.data?.total_count;
        state.message = action?.payload?.data?.message;
        state.risk = action?.payload?.data?.severity;
        state.riskColor = action?.payload?.data?.color;
        state.success = true;
        state.loading = false;
      })
      .addCase(riskStatusGraph.pending, (state, action) => {
        state.message = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(riskStatusGraph.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.message;
        state.loading = false;
        state.success = false;
      });
  },
});

export default associatesListingSlice.reducer;
