import { configureStore } from "@reduxjs/toolkit";

import associatesListingSlice from "./associates-listing/associatesListingSlice";
import changePasswordSlice from "./change-password/changePasswordSlice";
import forgotPasswordSlice from "./forgot-password/forgotPasswordSlice";
import inviteSlice from "./invite/inviteSlice";
import loginSlice from "./login/loginSlice";
import masterSlice from "./master/masterSlice";
import userDetailSlice from "./user-detail/userDetailSlice";
import userProfileSlice from "./user-profile/userProfileSlice";

export const store = configureStore({
  reducer: {
    forgotPassword: forgotPasswordSlice,
    login: loginSlice,
    master: masterSlice,
    associatesListing: associatesListingSlice,
    userProfile: userProfileSlice,
    invite: inviteSlice,
    userDetail: userDetailSlice,
    changePassword: changePasswordSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
