import axios from 'axios';
/* import AxiosMockAdapter from 'axios-mock-adapter';

import { API_MOCKING_ENABLED } from '../config/const';

const axiosMockInstance = axios.create();
const axiosLiveInstance = axios.create();

export const axiosMockAdapterInstance = new AxiosMockAdapter(
  axiosMockInstance, 
  { delayResponse: 1000, onNoMatch: 'passthrough' }
);

export default API_MOCKING_ENABLED ? axiosMockInstance : axiosLiveInstance; */

export default axios.create();
