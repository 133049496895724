import { createAsyncThunk } from "@reduxjs/toolkit";

import Request from "../../helpers/request.service";

export const changePassword = createAsyncThunk(
  "changePassword",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.putJson(`/change-password`, props.data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
