import { createSlice } from "@reduxjs/toolkit";

import Auth from "../../helpers/auth.service";
import { login } from "./loginAction";

const initialState = {
  token: Auth.token,
  message: null,
  success: null,
  loading: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.message = action?.payload;
        state.success = true;
        state.loading = false;
      })
      .addCase(login.pending, (state, action) => {
        state.message = null;
        state.token = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.detail?.message;
        state.loading = false;
        state.success = false;
      });
  },
});

export default loginSlice.reducer;
