import { createAsyncThunk } from "@reduxjs/toolkit";
import jwt from "jwt-decode";

import Auth from "../../helpers/auth.service";
import { history } from "../../helpers/history";
import Request from "../../helpers/request.service";
import Storage from "../../helpers/storage.service";

export const login = createAsyncThunk(
  "login",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.postJson("/login", props.data);

      if (response.status === 200) {
        Auth.token = response?.data?.data?.access_token;
        const user_role = response?.data?.data?.role;
        const decoded_token = jwt(response?.data?.data?.access_token);
        Storage.setObject("user", {
          id: btoa(decoded_token?.sub),
          name: btoa(response?.data?.data?.name),
          role: btoa(response?.data?.data?.role),
        });
        if (user_role === "Associate") {
          history.push("/app/user-detail");
        } else {
          history.push("../../app/associates-list");
        }
      }

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
