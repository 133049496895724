import { createAsyncThunk } from "@reduxjs/toolkit";

import Request from "../../helpers/request.service";
import { history } from "../../helpers/history";

export const inviteUserStep1 = createAsyncThunk(
  "inviteUser/SendEmail",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.postJson("/invite-user", props?.payload);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const inviteUserStep2 = createAsyncThunk(
  "inviteUser/Validate",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get("/validate-invite", "", {
        headers: {
          token: props?.token,
        },
      });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const inviteUserStep3 = createAsyncThunk(
  "inviteUser/CreateUser",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.postJsonWithHeaders(
        "/add-invited-user",
        props?.payload,
        { headers: { "intellisafe-token": props?.token } }
      );
      if (response?.status === 201) {
        history.push("/user/register-success");
      }
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const invitedUserList = createAsyncThunk(
  "inviteUser/List",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get("/list-invites", props?.params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getJobRoles = createAsyncThunk(
  "Register/getJobRoles",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get("/master/job-roles");
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
